import React, { useEffect } from 'react';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Heading from '../../../layout/admin/heading';
import AutoForm from '../../../form/auto-form';
import { formatDay } from '../../../../utils/helpers';
import { getCenterAdminToken } from '../../../../utils/auth';
import SEO from '../../../layout/seo';

const CenterAdminProduct = ({ productId, leagueId }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const breadcrumbs = [
      {
        href: '/app/center-admin/products',
        label: 'Products',
      },
    ];
    if (leagueId) {
      breadcrumbs.push({
        href: '/app/center-admin/products/choose-league',
        label: 'Choose League',
      });
      breadcrumbs.push({
        href: `/app/center-admin/products/league/create?id=${leagueId}`,
        label: 'Create Product',
      });
    } else {
      breadcrumbs.push({
        href: `/app/center-admin/product/edit?id=${productId}`,
        label: `Update Product`,
      });
    }
    setBreadcrumbs(breadcrumbs);
  }, [productId, leagueId]);

  const getPasswordString = `password=${getCenterAdminToken()}`;

  const productForm = {
    type: 'post',
    fetchRoute: productId ? `/center-admin/product/${productId}?${getPasswordString}` : `/center-admin/league/${leagueId}?${getPasswordString}`,
    fetchErrorRedirect: '/center-admin/products',
    fetchErrorMessage: 'There was an error while attempting to load the requested product.',
    postRoute: `/center-admin/product${productId ? `/${productId}` : ''}`,
    beforePost: (values, actions, product) => {
      // Ensure we send the league id to the server
      if (leagueId) {
        return {
          ...values,
          type: 'league',
          league_id: leagueId,
          password: getCenterAdminToken(),
        };
      } else {
        return {
          ...values,
          type: 'league',
          league_id: product.league_id,
          password: getCenterAdminToken(),
        };
      }
    },
    successRedirect: '/app/center-admin/products',
    successNotification: 'The product was saved.',
    fields: [
      {
        name: 'name',
        label: 'Product Name',
        type: 'text',
        required: true,
      },
      {
        name: 'short_description',
        label: 'Short Description',
        type: 'textarea',
        helpText: 'Will be used in lists and preview interfaces, as well as the top of full page displays.',
      },
      {
        name: 'description',
        label: 'Long Description',
        type: 'markdown',
        helpText: 'Full details about the league.',
      },
      {
        name: 'schedule_info',
        label: 'Schedule Info',
        type: 'textarea',
        helpText: 'Optional scheduling information to present to people registering.',
      },
      {
        name: 'price',
        label: 'Registration Price',
        type: 'text',
        required: true,
        helpText: 'Only include numbers, no commas or dollar signs.',
      },
      {
        name: 'session_fee',
        label: 'Session Fee',
        type: 'text',
        helpText: 'The fee a bowler can expect to pay at each league session. Only include numbers, no commas or dollar signs.',
      },
      {
        name: 'min_age',
        label: 'Minimum Age',
        type: 'text',
        required: true,
      },
      {
        name: 'team_size',
        label: 'Team Size',
        type: 'text',
        helpText: 'Whole numbers only.',
        required: true,
      },
      {
        name: 'num_weeks',
        label: 'Number of Weeks',
        type: 'number',
        required: true,
      },
      {
        name: 'num_games',
        label: 'Games Per Week',
        type: 'number',
        required: true,
      },
      {
        name: 'total_stock',
        label: 'Total Stock',
        type: 'text',
        required: true,
        helpText: 'The number of people that can register.',
      },
      {
        name: 'start_date',
        label: 'Start Date',
        type: 'date',
        required: true,
        helpText: 'Enter the date that this league is scheduled to start.',
        initialValue: (product) => {
          const { start_date: startDate } = product || {};
          return startDate ? formatDay(startDate, 'MM/DD/Y') : null;
        },
      },
      {
        name: 'start_time',
        label: 'Start Time',
        type: 'time',
        required: true,
        helpText: 'Enter the time that this league is scheduled to start.',
      },
      {
        name: 'registration_end_date',
        label: 'Last Day To Register',
        type: 'date',
        required: true,
        helpText: 'Enter the last day that people can register for this league.',
        initialValue: (product) => {
          const { registration_end_date: endDate } = product || {};
          return endDate ? formatDay(endDate, 'MM/DD/Y') : null;
        },
      },
      {
        name: 'image',
        type: 'file',
        label: 'Cover Photo',
        maxFiles: 1,
        isImage: true,
        multiple: false,
        helpText: 'Cover photo for the product. Should be roughly square dimensions.',
      },
      {
        name: 'banner_image',
        type: 'file',
        label: 'Banner Image',
        maxFiles: 1,
        isImage: true,
        multiple: false,
        helpText: 'Banner image for the product. Should be roughly square dimensions.',
      },
      {
        name: 'team_registrations',
        label: 'Team Registration Settings',
        type: 'checkbox',
        title: 'Only team captains can register',
      },
    ],
    submitLabel: `${productId ? 'Update' : 'Create'} Product`,
  };

  return (
    <AdminContainer centerAdmin>
      <SEO title={`${productId ? 'Update' : 'Create'} Product`} />
      <Heading title={`${productId ? 'Update' : 'Create'} Product`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...productForm} />
      </div>
    </AdminContainer>
  );
}

export default CenterAdminProduct;
